import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactFooter from "../components/ContactFooter";
import ImgTextModule from "../components/ImgTextModule";
import InfoSection from "../components/InfoSection";
import Line from "../components/Line";
import NewsFrontpage from "../components/NewsFrontpage";
import ScrollComponent6LawsPlusInfo from "../components/ScrollComponent6Laws+Info";
import TeamQuadLink from "../components/TeamQuadLink";
import TokentusHeader from "../components/TokentusHeader";
import TriFeature from "../components/triFeature";
import AnimatedCountUpDecimal from "../DefinitionComponents/Animation-Components/AnimatedCountUpDecimal";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import MagicText from "../DefinitionComponents/Complete-Components/MagicText";
import GImage from "../DefinitionComponents/Utility/GImage";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";

const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  return (
    <>
      <Helmet>
        <title>tokentus |  Blockchain your investment</title>
        <meta
          name="description"
          content="tokentus investment AG is a venture capitalist investing worldwide in blockchain based business models. Based in Frankfurt, Germany. "
        />
        <link rel="canonical" href="https:/tokentus.com" />
      </Helmet>

      {/* CONTAINER TOKENTUSHEADER */}
      <TokentusHeader />

      {/* CONTAINER MAGICTEXT */}
      <Container minW="100%" mt={0.5} p={0}>
        <MagicText
          text={
            "Blockchain is right there where internet and telecommunications were at the beginning of the 90s."
          }
          quoteOrigin="– Oliver Michel –"
          fontSize={{ md: "6xl", base: "3xl" }}
          coloranimtext={"#14ffa4"}
          scrollLength={"250vh"}
          gap={{ md: 0, base: 0 }} //gap between words
          videoSrc="c10.mp4"
          scrollStartFactor={-0.1}
          scrollEndFactor={0.5}
          textColor={"brand.green"}
          showProgressCircle={true}
          progressColor={"brand.green"}
          showProgressLine={true}
          progressLineColor={"brand.green"}
        />
      </Container>

      <Box w="100%">
        <Container variant="layoutContainer" px={10} py={20}>
          <Box w={{ lg: "100%", base: "100%" }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={4}
                  align="stretch"
                >
                  <Box minW={"50%"}>
                    <MaskSlidInAnimation threshold={1} duration={0.6}>
                      <Heading
                        fontSize={{ lg: "6xl", md: "4xl", base: "3xl" }}
                        fontWeight="normal"
                        w={"100%"}
                        letterSpacing="tighter"
                        color="brand.blue"
                      >
                        The time is now.
                      </Heading>
                    </MaskSlidInAnimation>
                  </Box>
                  <Box>
                    <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                      <Heading
                        mt={0}
                        mb="4"
                        fontSize={{ lg: "3xl", md: "2xl", base: "xl" }}
                        fontWeight="normal"
                        color="brand.medgray"
                      >
                        Benefit financially from the massive rise of the global
                        blockchain market.
                      </Heading>
                    </FadeInAnimation>
                    <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                      <Heading
                        fontSize={{ lg: "xl", md: "xl", base: "md" }}
                        fontWeight="normal"
                        color="brand.darkgray"
                      >
                        Blockchain based investments are gaining traction. We
                        have strong expertise in this young discipline with a
                        distinctive focus and a successful track record.
                      </Heading>
                    </FadeInAnimation>
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
        </Container>
      </Box>

      {/* CONTAINER SCROLLCOMPONENT */}
      {/* <Container variant='layoutContainer'> */}
      <Box my={0.5}>
        <ScrollComponent6LawsPlusInfo />
      </Box>
      <Box w="100%" bg="" mt={24}>
        <Container variant="layoutContainer" px={0}>
          <InfoSection
            triggerOnce={true}
            minH={"85vh"}
            video={true}
            imgSrc="logos_a.mp4"
            bgColor="black"
            imgOpacity={1}
            toplineText={"Portfolio"}
            toplineColor={"white"}
            toplineFs={{ lg: "lg", base: "sm" }}
            lineColor={"white"}
            headingText={"Results."}
            headingFs={{ xl: "6xl", md: "6xl", base: "3xl" }}
            headingColor={"white"}
            sublineText={"Our investments are our benchmark."}
            sublineColor={"white"}
            sublineFs={{ xl: "2xl", md: "xl", base: "lg" }}
            textFs={"sm"}
            textColor={"white"}
            numberToplineColor={"brand.white"}
            numberToplineText="Companies"
            numberToplineFs={{ lg: "lg", base: "xs" }}
            countTo={11}
            numberFs={{ lg: "12rem", base: "3rem" }}
            numberCellHeight={{ lg: "12rem", base: "3rem" }}
            numberColor="brand.medlightgray"
            numberLetterSpacing={0}
            btnVariant={""}
            btnBg={"brand.green"}
            btnColor={"black"}
            btnText={"Portfolio"}
            link={"/portfolio"}
            isExternal={false}
            justifyContent="start"
            boxPadding={{ md: 16, base: 8 }}
          />
        </Container>
      </Box>
      {/*  CONTAINER Featured Target*/}

      {/* CONTAINER INFOSECTION */}

      <Box w="100%" bg="white">
        <Container variant="layoutContainer" mt={0}>
          <InfoSection
            justifyContent={{ base: "flex-start", md: "flex-end" }}
            triggerOnce={true}
            minH={{ base: "100vh", lg: "80vh" }}
            imgSrc={"team_01.jpg"}
            imgOpacity={1}
            bgColor="white"
            toplineText={"A strong team"}
            toplineColor={"brand.white"}
            toplineFs={"lg"}
            lineColor={"brand.white"}
            headingText={"Experts."}
            headingFs={{ xl: "6xl", md: "6xl", base: "3xl" }}
            headingColor={"white"}
            sublineText={"Diversity in knowledge and expertise."}
            sublineColor={"white"}
            sublineFs={{ xl: "2xl", md: "xl", base: "lg" }}
            textFs={"xs"}
            textColor={"white"}
            numberToplineColor={"brand.white"}
            numberToplineText="Members"
            numberToplineFs={{ base: "xs", lg: "lg" }}
            countTo={4}
            numberFs={{ lg: "12rem", base: "2rem" }}
            numberCellHeight={{ lg: "12rem", base: "2rem" }}
            numberColor="brand.green"
            numberLetterSpacing={0}
            btnBg={"brand.green"}
            btnColor={"black"}
            btnText={"Say hello"}
            link={"/team"}
            isExternal={false}
            boxPadding={{ md: 16, base: 8 }}
          />
        </Container>
      </Box>

      <TeamQuadLink />
      <Container variant="layoutContainer" mt={0.5}>
        <ImgTextModule
          w="100%"
          src={
            "240810_Business_Businessman_Client_Clouds_by_Monster_Filmmakers_Artgrid-HD_H264-HD.mp4"
          }
          delay={0.9}
          duration={1}
          threshold={0.4}
          colStart={3}
          colEnd={4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8} px={{ base: 8, lg: 16 }} minH="55vh">
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  mt={40}
                  fontSize={{ xl: "lg", md: "md", base: "md" }}
                >
                  Stock listed
                </Text>
              </FadeInAnimation>
              <MaskSlidInAnimation threshold={1} duration={0.6}>
                <Heading
                  fontSize={{ xl: "6xl", md: "6xl", base: "3xl" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                  color="brand.white"
                  my={4}
                >
                  Stability, Transparency, Flexibility.
                </Heading>
              </MaskSlidInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text
                  color="brand.lightgray"
                  maxW={{ xl: "55%", md: "80%", base: "100%" }}
                  fontSize={{ xl: "2xl", md: "xl", base: "lg" }}
                >
                  As a stock listed company we deliver more.
                  <br /> More information. More reliability. More transparency.
                  <br />
                  And since we are publicly traded. More flexibility.
                </Text>
              </FadeInAnimation>
              <InViewTrigger threshold={0.1} triggerOnce={false}>
                {(inView) => (
                  <Box py={8}>
                    <Flex
                      ml={"0%"}
                      w="30%"
                      justifyContent="flex-end"
                      flexDirection={{ base: "column", lg: "row" }}
                      gap={{ base: 8, lg: 0 }}
                    >
                      <Box w="100%" mr={4}>
                        <FadeInAnimation
                          duration={1.5}
                          delay={0}
                          initialY={40}
                          threshold={0.4}
                        >
                          <Text color="white" fontSize="xs" mb={4}>
                            Number of stocks
                          </Text>
                        </FadeInAnimation>
                        <Line
                          duration={2}
                          delay={0}
                          inView={inView}
                          bg={"brand.medgray"}
                          h={"1px"}
                          my={2}
                        />
                        <AnimatedCountUpDecimal
                          inView={inView}
                          unit="Million "
                          fontSizeUnit="md"
                          count={8}
                          fontSize={"6rem"}
                          duration={2}
                          decimal={true}
                          decimalDuration={2}
                          decimalCount={43}
                          unitSpacing={3}
                          numberColor={"brand.green"}
                          textColor="white"
                          cellHeight={"6rem"}
                          letterSpacing={"-0.2rem"}
                        />
                      </Box>
                    </Flex>
                  </Box>
                )}
              </InViewTrigger>
              <RouterLink isExternal={false} link={"/investorrelations"}>
                <Button
                  mt={4}
                  variant="solid"
                  bg="brand.green"
                  color="brand.darkgray"
                >
                  Investor Relations
                </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </Container>

      {/* NEWS */}

      <Box w="100%">
        <Container
          variant="layoutContainer"
          px={{ base: 10, lg: 10 }}
          pt={{ base: 20, lg: 10 }}
          pb={{ base: 10, lg: 10 }}
          mt={{ base: 20, lg: 10 }}
        >
          <Box w={{ lg: "100%", base: "100%" }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={4}
                  align="stretch"
                >
                  <Flex minW={"20%"}>
                    <AnimatedCountUpDecimal
                      inView={inView}
                      unit=" "
                      fontSizeUnit="md"
                      count={3}
                      fontSize={"4rem"}
                      duration={2}
                      decimal={false}
                      decimalDuration={2}
                      decimalCount={34}
                      unitSpacing={4}
                      numberColor={"brand.medgray"}
                      textColor="white"
                      cellHeight={"4.7rem"}
                      letterSpacing={"0rem"}
                    />
                    <MaskSlidInAnimation threshold={1} duration={0.6}>
                      <Heading
                        fontSize={{ xl: "4rem", md: "4rem", base: "4rem" }}
                        fontWeight="normal"
                        w={"100%"}
                        letterSpacing="tighter"
                        color="brand.blue"
                      >
                        News.
                      </Heading>
                    </MaskSlidInAnimation>
                  </Flex>
                  <Box w={{ base: "70%", lg: "60%" }}>
                    <FadeInAnimation threshold={0.4} duration={1.2} delay={0.8}>
                      <Heading
                        mt={0}
                        mb="4"
                        pl={{ base: 0, lg: 8 }}
                        fontSize={{ base: "lg", lg: "3xl" }}
                        fontWeight="normal"
                        color={{
                          base: "brand.darkmedgray",
                          lg: "brand.medgray",
                        }}
                      >
                        The latest 3 news about our activities from the world of
                        blockchain based investments.{" "}
                      </Heading>
                    </FadeInAnimation>
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
        </Container>
      </Box>
      <NewsFrontpage />
      <TriFeature />
      <Container variant={"layoutContainer"} px={16} mb={16}>
        <RouterLink isExternal={false} link={"/investorrelations"}>
          <Button
            mt={4}
            variant="solid"
            bg="brand.meddarkgray"
            color="brand.white"
          >
            News Archive
          </Button>
        </RouterLink>
      </Container>

      <ContactFooter
        title="Are you ready ?"
        name="Oliver Michel, CEO"
        email="contact@tokentus.com"
        phone="+49 175 72 22 351"
      />
    </>
  );
};

export default IndexPage;
